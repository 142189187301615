import React from "react";
import { Redirect, Route } from "react-router";

//handle the public routes
function PublicRoutes({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !sessionStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/home" }} />
        )
      }
    />
  );
}

export default PublicRoutes;
