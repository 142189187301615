import React, { useState } from "react";
import "./login.styles.css";
import Logo from "../../images/wa-logo.png";
import Footer from "../footer/footer.component";
import axios from "axios";
import { setUserSession } from "../../utils/common";
import api from "../services/api";

const API_KEY = process.env.REACT_APP_API_KEY

const Login = (props) => {
  const email = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState("");

  //handle onclick button
  const handleLogin = (event) => {
    setError(null);
    event.preventDefault();
    var data = JSON.stringify({
      email: email.value,
      password: password.value,
    });

    var config = {
      method: "post",
      url: api.login,
      headers: {
        'api_key': API_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
    .then((response) => {
            if (response.data.success) {
              setUserSession(
                response.data.result.userId,
                response.data.result.email
              );
              props.history.push("/home");
            } else {
              setError(response.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
  };

  return (
    <>
      <div className="container-fluid login__container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 text-center pt-4">
            <a
              className="navbar-brand"
              href="https://www.wiseradvisor.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Logo} width="60%" alt="WiserAdvisor" />
            </a>
          </div>
        </div>

        <div className="col-lg-12"></div>

        <div className="row login__form">
          <div className="col-lg-4 login__column">
            <div className="main" id="loginform">
              <p className="sign" align="center">
                Sign in
              </p>
              {error && (
                <>
                  <small style={{ color: "red" }} className="error__text">
                    {error}
                  </small>
                  <br />
                </>
              )}
              <br />
              <form className="form1">
                <input
                  {...email}
                  id="email"
                  name="email"
                  placeholder="Username"
                  type="text"
                  align="center"
                  className="form-control un"
                  required="required"
                />

                <input
                  {...password}
                  id="password-field"
                  name="password"
                  placeholder="Password"
                  type="password"
                  align="center"
                  className="pass form-control"
                  required="required"
                  data-toggle="password"
                />

                <button
                  onClick={handleLogin}
                  type="submit"
                  className="submit"
                  id="submit"
                  align="center"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
