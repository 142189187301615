import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { ImUserMinus } from "react-icons/im";
import { ImUserPlus } from "react-icons/im";
import { BiReset } from "react-icons/bi";
import Header from "../header/header.component";
import Footer from "../footer/footer.component";
import "./userspage.styles.css";
import useLoader from "../loader/useLoader";
import { Link } from "react-router-dom";
import api from "../services/api";

const API_KEY = process.env.REACT_APP_API_KEY;

const UserPage = (props) => {
  const [userList, setUserList] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const [isActivate, setIsActivate] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [totalactiveuser, setTotalactiveuser] = useState("");
  const [totalinactiveuser, setTotalInactiveuser] = useState("");
  const emailId = sessionStorage.getItem("email");

  useEffect(() => {
    showLoader();

    var config = {
      method: "get",
      url: api.users,
      headers: {
        api_key: API_KEY,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list = response.data.result.completeList;
        setUserList(list);
        setTotalactiveuser(response.data.result.totalActive);
        setTotalInactiveuser(response.data.result.totalInactive);
        hideLoader();
      })
      .catch(function (error) {
        console.log(error);
      });

    return () => {};
  }, [isDeactivated, isActivate]);

  //This funtion handles the deactivate api call
  const HandleDeactivate = (userId, csrId, roles, email) => {
    var empdata = JSON.stringify({
      userId: userId,
      csrId: csrId,
      actionBy: emailId,
      roles: roles,
      activeStatus: "N",
    });

    var configDeactivate = {
      method: "put",
      url: api.status,
      headers: {
        api_key: API_KEY,
        "Content-Type": "application/json",
      },
      data: empdata,
    };

    axios(configDeactivate)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.result));
        alert(response.data.result);
        setIsDeactivated(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //this function handles the activate user api call
  const HandleActivate = (userId, csrId, roles, email) => {
    var empdata = JSON.stringify({
      userId: userId,
      csrId: csrId,
      actionBy: emailId,
      roles: roles,
      activeStatus: "Y",
    });
    var configActivate = {
      method: "put",
      url: api.status,
      headers: {
        api_key: API_KEY,
        "Content-Type": "application/json",
      },
      data: empdata,
    };

    axios(configActivate)
      .then(function (response) {
        setIsActivate(true);
        alert(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //this function handles the reset password api call
  const HandleResetPassword = (userId) => {
    var config = {
      method: "get",
      url: api.resetLink + userId,
      headers: {
        api_key: API_KEY,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        alert(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <Header {...props} />
        {loader}

        <div className="row">
          <div className="col-12 user__title text-center">
            <h1>User List</h1>
          </div>

          <div className="col-lg-12 col-12">
            <div className="row">
              <div className="col-lg-12">
                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <a
                      href="#active"
                      className="nav-link active"
                      data-toggle="tab"
                    >
                      Active Users
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#inactive" className="nav-link" data-toggle="tab">
                      Inactive Users
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={{
                        pathname: "/new-user",
                      }}
                      className=" addUser btn btn-primary"
                    >
                      New Users
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="active">
                <div className="page">
                  <div className="month-detail text-left">
                    <span>
                      Total Active Users: <b>{totalactiveuser}</b>
                    </span>
                  </div>
                </div>

                <div style={{ overflowX: "auto" }}>
                  <table className="table table-bordered">
                    <thead>
                      <tr role="row">
                        <th width="10%">Name</th>
                        <th width="15%">Email</th>
                        <th width="10%">Mobile</th>
                        <th width="10%">Title</th>
                        <th width="15%">Role</th>
                        <th width="20%">Bigbrother Role</th>
                        <th width="10%">Edit/Deactivate</th>
                        <th width="5%">Reset Password</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((item) => {
                        if (item.activeStatus === "Y") {
                          return (
                            <tr role="row" className="odd" key={item.userId}>
                              <td className="ExportLabelTD">{item.csrName}</td>
                              <td className="ExportLabelTD">{item.email}</td>
                              <td className="ExportLabelTD">{item.phone}</td>
                              <td className="ExportLabelTD">{item.title}</td>
                              <td className="ExportLabelTD">{item.roles}</td>
                              <td
                                className="ExportLabelTD"
                                style={{ wordBreak: "break-all" }}
                              >
                                {item.bbroles}
                              </td>
                              <td className="NoExport" align="center">
                                <Link
                                  to={{
                                    pathname: "/edit-user",
                                    state: { userId: item.userId },
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="editbtn"
                                    // onClick={() => handleEditClick(item.userId)}
                                  >
                                    <BiEdit size={20} color="orange" />
                                  </button>
                                </Link>
                                <button
                                  type="button"
                                  className="deactivate"
                                  title="Deactivate"
                                  onClick={() => {
                                    if (window.confirm("Deactivate the user"))
                                      HandleDeactivate(
                                        item.userId,
                                        item.csrId,
                                        item.roles,
                                        emailId
                                      );
                                  }}
                                >
                                  <ImUserMinus size={20} color="red" />
                                </button>
                              </td>
                              <td className="NoExport" align="center">
                                <button
                                  type="button"
                                  className="deactivate"
                                  title="Activate"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Do you want to reset the password?"
                                      )
                                    )
                                      HandleResetPassword(item.userId);
                                  }}
                                >
                                  <BiReset size={20} color="lightseagreen" />
                                </button>
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="inactive">
                <div
                  id="active_users"
                  className="page"
                  data-page="active_users"
                >
                  <div className="month-detail" style={{ textAlign: "left" }}>
                    <span>
                      Total Inactive Users: <b>{totalinactiveuser}</b>
                    </span>
                  </div>
                </div>

                <div style={{ overflowX: "auto" }}>
                  <table className="table table-bordered">
                    <thead>
                      <tr role="row">
                        <th width="15%">Name</th>
                        <th width="15%">Email</th>
                        <th width="10%">Mobile</th>
                        <th width="15%">Title</th>
                        <th width="15%">Role</th>
                        <th width="20%">Bigbrother Role</th>
                        <th width="10%">Edit/Deactivate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((item) => {
                        if (item.activeStatus === "N") {
                          return (
                            <tr role="row" className="odd" key={item.userId}>
                              <td className="ExportLabelTD">{item.csrName}</td>
                              <td className="ExportLabelTD">{item.email}</td>
                              <td className="ExportLabelTD">{item.phone}</td>
                              <td className="ExportLabelTD">{item.title}</td>
                              <td className="ExportLabelTD">{item.roles}</td>
                              <td
                                className="ExportLabelTD"
                                style={{ wordBreak: "break-all" }}
                              >
                                {item.bbroles}
                              </td>
                              <td className="NoExport" align="center">
                                <button
                                  type="button"
                                  className="deactivate"
                                  title="Activate"
                                  onClick={() => {
                                    if (window.confirm("Activate the user"))
                                      HandleActivate(
                                        item.userId,
                                        item.csrId,
                                        item.roles,
                                        emailId
                                      );
                                  }}
                                >
                                  <ImUserPlus size={20} color="green" />
                                </button>
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
    // <div></div>
  );
};

export default UserPage;
