import React, { useState, useEffect } from "react";
import "./header.style.css";

import Logo from "../../images/wa-logo.png";
import { removeUserSession } from "../../utils/common";

const Header = (props) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    var emailId = JSON.parse(sessionStorage.getItem("email"));
    setEmail(emailId);
  }, []);
  const handleLogout = () => {
    removeUserSession();
    props.history.push("/");
  };
  return (
    <div className="row header__container">
      <div className="col-md-4 col-12 col-lg-4">
        <a
          className="navbar-brand"
          href="https://www.wiseradvisor.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={Logo}
            width="100%"
            height="auto"
            style={{ maxWidth: "150px" }}
            alt="WiserAdvisor"
          />
        </a>
      </div>
      <div className="col-md-8 col-12 col-lg-8">
        <div className="logoutcheck">
          <p style={{ float: "left", marginRight: "20px" }}>{email}</p>

          <button onClick={handleLogout} className="btn btn-outline-primary">
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
