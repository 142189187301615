import React from "react";

const Checkbox = (props) => {
  return (
    <div>
      <li>
        <input
          key={props.id}
          onClick={props.handleClickChildElementRoles}
          type="checkbox"
          checked={props.isChecked} //false ya true
          value={props.value}
          name={props.name}
        />
        {props.name}
      </li>
    </div>
  );
};

export default Checkbox;
