import "./App.css";
import { Switch, BrowserRouter } from "react-router-dom";
import Login from "./components/login/login.component";
import UserPage from "./components/Users-page/userpage.component";
import PublicRoutes from "./utils/publicRoute";
import PrivateRoute from "./utils/privateRoute";
import EditUser from "./components/edit-user/edit-user.component";
import NewUser from "./components/add-new/new-user1.component";

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <PublicRoutes path="/" exact component={Login} />
          <PrivateRoute path="/home" component={UserPage} />
          <PrivateRoute path="/edit-user" component={EditUser} />
          <PrivateRoute path="/new-user" component={NewUser} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
