import React from 'react'
import Spinner from '../../images/loading.gif'
import './loader.style.css'

const Loader = () => {
    return (
        <div className="spinner__container">
            <img src={Spinner} alt="spinner" className="spinner__image" />
        </div>
    )
}

export default Loader
 