export const getUser = () => {
  const userItem = sessionStorage.getItem("user");
  if (userItem) return JSON.parse(userItem);
  else return null;
};

//return token from session storage
// export const getToken = () => {
//   return sessionStorage.getItem("token") || null;
// };

//remove token and user from local storage

export const removeUserSession = () => {
  // sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  // console.log("session end")
};

export const setUserSession = (user, email) => {
  // sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
  sessionStorage.setItem("email", JSON.stringify(email));
};
