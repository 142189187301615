import React, { Component } from "react";
import Checkbox from "../checkbox/checkbox.component";
import "./new-user.style.css";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import Header from "../header/header.component";
import Footer from "../footer/footer.component";
import api from "../services/api";

const API_KEY = process.env.REACT_APP_API_KEY;

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [
        { id: 1, value: "SU_ADMIN", isChecked: false, name: "Super Admin" },
        { id: 2, value: "ADMIN", isChecked: false, name: "Admin" },
        { id: 3, value: "AM", isChecked: false, name: "Affiliate Manager" },
        { id: 4, value: "CSR", isChecked: false, name: "CSR" },
        { id: 5, value: "CMS", isChecked: false, name: "CMS" },
        {
          id: 6,
          value: "SBOARD",
          isChecked: false,
          name: "Service dahsboard Dropdown",
        },
        {
          id: 7,
          value: "VM",
          isChecked: false,
          name: "Vertical Manager",
        },
        { id: 8, value: "PR_Admin", isChecked: false, name: "PR Admin" },
        { id: 9, value: "FA_Admin", isChecked: false, name: "FA Admin" },
        { id: 10, value: "RP_Admin", isChecked: false, name: "RP Admin" },
        { id: 11, value: "FP_Admin", isChecked: false, name: "FP Admin" },
      ],
      bbroles: [
        { id: 1, value: "BB_ADMIN", isChecked: false, name: "BB Admin" },
        { id: 2, value: "BB_REQ_MON", isChecked: false, name: "Request Cop" },
        {
          id: 3,
          value: "BB_MM_MON",
          isChecked: false,
          name: "Manual Matching Cop",
        },
        { id: 4, value: "BB_CAT_MON", isChecked: false, name: "Category Cop" },
        { id: 5, value: "BB_ET_ADMN", isChecked: false, name: "Content Cop" },
        {
          id: 6,
          value: "BB_DS_COP",
          isChecked: false,
          name: "Performance Dashboard",
        },
        {
          id: 7,
          value: "BB_SER_DS",
          isChecked: false,
          name: "Service Dashboard",
        },
        { id: 8, value: "BB_READ_ON", isChecked: false, name: "Read Only" },
      ],
      newUser: {
        fullName: "",
        email: "",
        altEmail: "",
        mobile: "",
        altMobile: null,
        title: null,
        upgradeAmount: null,
        upgradeTarget: null,
        salesAmount: null,
        salesTarget: null,
      },
      actionBy: "",
      redirectToReferrer: false,
      rolesString: "",
      bbrolesString: "",
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const newuser = { ...this.state.newUser, [name]: value };

    this.setState({
      newUser: newuser,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      fullName,
      email,
      mobile,
      altMobile,
      title,
      upgradeAmount,
      upgradeTarget,
      salesAmount,
      salesTarget,
      altEmail,
    } = { ...this.state.newUser };

    let rolesstr = [];
    this.state.roles.map((item) => {
      return item.isChecked === true ? rolesstr.push(item.value) : null;
    });
    let bbrolesstr = [];
    this.state.bbroles.map((item) => {
      return item.isChecked === true ? bbrolesstr.push(item.value) : null;
    });

    var actionBy = JSON.parse(sessionStorage.getItem("email"));

    // console.log(rolesstr);
    const finalNewUser = {
      fullName: fullName,
      email: email,
      altEmail: altEmail,
      mobile: mobile,
      altMobile: altMobile,
      title: title,
      upgradeAmount: upgradeAmount,
      upgradeTarget: upgradeTarget,
      salesAmount: salesAmount,
      salesTarget: salesTarget,
      actionBy: actionBy,
      roles: rolesstr.join(),
      bbroles: bbrolesstr.join(),
    };

    console.table(finalNewUser);

    if (finalNewUser.roles === "") {
      alert("Please fill the Password Lookup Roles");
    } else if (finalNewUser.bbroles === "") alert("Please fill the BB Roles");
    else {
      var data = JSON.stringify(finalNewUser);
      var config = {
        method: "post",
        url: api.users,
        headers: {
          api_key: API_KEY,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          if (response.data.success) {
            alert("User has been updated successfully!");
            this.setState({ redirectToReferrer: true });
          } else {
            alert(response.data.message);
            // console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleClickChildElementRoles = (event) => {
    let roles = this.state.roles;
    roles.forEach((roles) => {
      if (roles.value === event.target.value)
        roles.isChecked = event.target.checked;
    });

    //for showing the selected value in the dropdown button
    const arr = [];
    roles.map((item) => {
      return item.isChecked ? arr.push(item.name) : null;
    });
    this.setState({ rolesString: arr.join(",") });

    this.setState({ roles: roles });

    let bbroles = this.state.bbroles;
    bbroles.forEach((bbroles) => {
      if (bbroles.value === event.target.value)
        bbroles.isChecked = event.target.checked;
    });
    this.setState({ bbroles: bbroles });
    const arr1 = [];
    bbroles.map((item) => {
      return item.isChecked ? arr1.push(item.name) : null;
    });
    // console.log(arr1);
    this.setState({ bbrolesString: arr1.join(",") });
  };

  render() {
    const redirectToReferrer = this.state.redirectToReferrer;
    if (redirectToReferrer) {
      return <Redirect to="/home" />;
    }

    return (
      <section>
        <div className="container-fluid">
          <Header {...this.props} />
        </div>
        <div className="addfullform" style={{ backgroundColor: "#dbeff7" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h1 className="pageheading text-center">Add New User</h1>
              </div>
            </div>
          </div>
          <div className="container mainaddform">
            <div id="addnew">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="input-label">Name*</label>
                      <input
                        name="fullName"
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Name"
                        required
                        value={this.state.newUser.fullName}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Email*</label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        required
                        id="newemail"
                        placeholder="Enter Email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        title="Please enter valid email"
                        value={this.state.newUser.email}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Alt Email</label>
                      <input
                        name="altEmail"
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        value={this.state.newUser.altEmail}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Mobile*</label>
                      <input
                        name="mobile"
                        type="text"
                        className="form-control"
                        maxLength="10"
                        required
                        id="newmobile"
                        pattern="[2-9][0-9]{9}"
                        title="first digit should not be 0 or 1"
                        placeholder="Enter Mobile"
                        value={this.state.newUser.mobile}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Alt-Mobile</label>
                      <input
                        name="altMobile"
                        type="text"
                        className="form-control"
                        maxLength="10"
                        id="newaltMobile"
                        pattern="[2-9][0-9]{9}"
                        title="first digit should not be 0 or 1"
                        placeholder="Enter Mobile"
                        value={this.state.newUser.altMobile}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="input-label">Title</label>
                      <input
                        name="title"
                        type="text"
                        className="form-control"
                        id="newtitle"
                        placeholder="Enter Title"
                        value={this.state.newUser.title}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Sales Target</label>
                      <input
                        name="salesTarget"
                        type="text"
                        className="form-control"
                        maxLength="5"
                        id="newsalesTarget"
                        placeholder="Enter Sales Target"
                        value={this.state.newUser.salesTarget}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">
                        Sales Target Amount ($)
                      </label>
                      <input
                        name="salesAmount"
                        type="text"
                        className="form-control"
                        maxLength="5"
                        id="newsalesAmount"
                        placeholder="Enter Sales Target Amount"
                        value={this.state.newUser.salesAmount}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">UpgradeTarget</label>
                      <input
                        name="upgradeTarget"
                        type="text"
                        className="form-control"
                        maxLength="5"
                        id="newupgradeTarget"
                        placeholder="Upgrade Target"
                        value={this.state.newUser.upgradeTarget}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">
                        Upgrade Target Amount ($)
                      </label>
                      <input
                        name="upgradeAmount"
                        type="text"
                        className="form-control"
                        maxLength="5"
                        id="newupgradeAmount"
                        placeholder="Upgrade Target Amount ($)"
                        value={this.state.newUser.upgradeAmount}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">
                        Password-lookup Role*
                      </label>
                      <span className="multiselect-native-select">
                        <div className="btn-group w-100">
                          <button
                            type="button"
                            className="multiselect dropdown-toggle btn btn-default"
                            data-toggle="dropdown"
                            title="Please select required roles."
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <span className="multiselect-selected-text">
                              {this.state.rolesString === "" ? (
                                <span>Please select required roles</span>
                              ) : (
                                this.state.rolesString
                              )}
                            </span>
                            <b className="caret"></b>
                          </button>
                          <ul className="multiselect-container dropdown-menu">
                            <li
                              className="multiselect-item multiselect-filter"
                              value="0"
                            >
                              <div className="input-group"></div>
                            </li>
                            <li className="multiselect-item multiselect-all">
                              <label
                                className="checkbox"
                                style={{ margin: "10px" }}
                              >
                                {/* <input
                                  type="checkbox"
                                  onClick={this.handleCheckAllRoles}
                                  value="checkedall"
                                />
                                Select all */}
                                <ul style={{ listStyle: "none" }}>
                                  {this.state.roles.map((roles) => {
                                    return (
                                      <Checkbox
                                        handleClickChildElementRoles={
                                          this.handleClickChildElementRoles
                                        }
                                        {...roles}
                                      />
                                    );
                                  })}
                                </ul>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="input-label">Bigbrother Roles*</label>
                      <span className="multiselect-native-select">
                        <div className="btn-group w-100">
                          <button
                            type="button"
                            className="multiselect dropdown-toggle btn btn-default"
                            data-toggle="dropdown"
                            title="Please Select all required BB roles."
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <span className="multiselect-selected-text">
                              {this.state.bbrolesString === "" ? (
                                <span>Please select required BB roles</span>
                              ) : (
                                this.state.bbrolesString
                              )}
                            </span>{" "}
                            <b className="caret"></b>
                          </button>
                          <ul className="multiselect-container dropdown-menu">
                            <li
                              className="multiselect-item multiselect-filter"
                              value="0"
                            ></li>
                            <li className="multiselect-item multiselect-all">
                              <label
                                className="checkbox"
                                style={{ marginLeft: "10px" }}
                              >
                                {/* <input
                                  type="checkbox"
                                  onClick={this.handleCheckAllBBRoles}
                                  value="checkedall"
                                />
                                Select all */}
                                <ul style={{ listStyle: "none" }}>
                                  {this.state.bbroles.map((bbroles) => {
                                    return (
                                      <Checkbox
                                        handleClickChildElementRoles={
                                          this.handleClickChildElementRoles
                                        }
                                        {...bbroles}
                                      />
                                    );
                                  })}
                                </ul>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="formbutton">
                        <button
                          className="btn btn-success addnewusermodal"
                          type="submit"
                          style={{ marginRight: "10px" }}
                        >
                          Add New User
                        </button>
                        <Link to="/home">
                          <button type="button" className="btn btn-secondary">
                            Close
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    );
  }
}

export default NewUser;
