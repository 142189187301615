import React from "react";
import "./footer.style.css";

const Footer = () => {
  return (
    <section className="upper_footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p style={{ color: "#757575" }}>
              Copyright {new Date().getFullYear()} 
              <a href="https://wiseradvisor.com"> WiserAdvisor</a>. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
